<template>
  <div class="Awarp">
    <dashCard
      class="cdzBox8"
      :operateWidth="operateWidth"
      :isLoading="isLoading"
      :gridOption="gridOption"
      @changeScreen="changeScreen"
    >
      <template slot="title">充电桩实时数据</template>
      <template slot="aside">
        <a-select
          v-model="searchObj['ITEM']"
          style="width: 220px"
          @change="handleChange"
          :getPopupContainer="triggerNode => triggerNode.parentNode"
        >
          <a-select-option
            v-for="(item, key) in itemList"
            :key="key"
            :value="item.assetnumber"
          >
            {{ item.machine_name }}
          </a-select-option>
        </a-select>
      </template>
      <div class="card-content1">
        <div>
          <img src="@/assets/imgs/add/cdz1.png" />
        </div>
        <div>
          <div>
            <span>电量</span>
            <span v-if="this.detailInfo.length != 0"> <font class="hot-num">{{detailInfo[3].num}}</font>kWh </span>
          </div>
          <div>
            <span>充电订单</span>
            <span v-if="this.detailInfo.length != 0">
              <font>{{detailInfo[4].num}}</font>
            </span>
          </div>
          <div>
            <span>累计充电时长</span>
            <span v-if="this.detailInfo.length != 0"> <font class="hot-num">{{detailInfo[5].num}}</font>h </span>
          </div>
          <div>
            <span>额定功率</span>
            <span v-if="this.detailInfo.length != 0"> <font>{{detailInfo[6].num}}</font>kW </span>
          </div>
        </div>
      </div>
      <div class="card-content2">
        <div>
          <font>电流(A)</font>
          <div class="content-li-line-box subbox">
            <span v-if="this.detailInfo.length != 0" :style="{ width: (detailInfo[1].num/detailInfo[1].count)*100 + '%' }" />
          </div>
          <font v-if="this.detailInfo.length != 0">{{ detailInfo[1].num }}</font>
        </div>
        <div>
          <font>电压(V)</font>
          <div class="content-li-line-box subbox">
            <span v-if="this.detailInfo.length != 0" :style="{ width: (detailInfo[2].num/detailInfo[2].count)*100 + '%' }" />
          </div>
          <font v-if="this.detailInfo.length != 0">{{ detailInfo[2].num }}</font>
        </div>
        <div>
          <font>功率(kW)</font>
          <div class="content-li-line-box subbox">
            <span v-if="this.detailInfo.length != 0" :style="{ width: (detailInfo[6].num/detailInfo[6].count)*100 + '%' }" />
          </div>
          <font v-if="this.detailInfo.length != 0">{{ detailInfo[6].num }}</font>
        </div>
      </div>
    </dashCard>
    <a-modal
      v-if="!isModal"
      class="Amodal"
      v-model="option.visible"
      :closable="false"
      :footer="null"
      :keyboard="false"
      width="100%"
      :dialog-style="{ top: '0px' }"
    >
      <div style="height: calc(100vh)">
        <cdzBox8 :option="option" :isModal="true" :orginSearchObj="searchObj" />
      </div>
    </a-modal>
  </div>
</template>
    <script>
export default {
  name: "cdzBox8",
  components: {
    dashCard: () => import("@/component/dashCard.vue"),
  },
  props: {
    gridOption: {
      type: Object,
      default: function () {
        return { w: 6, h: 6 };
      },
    },
    option: {
      type: Object,
      default: function () {
        return {
          visible: false,
        };
      },
    },
    isModal: {
      type: Boolean,
      default: function () {
        return false;
      },
    },
    orginSearchObj: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },
  watch: {
    gridOption: {
      handler: function (newVal, oldVal) {
        this.$nextTick((_) => {
          this.getOperateWidth();
        });
      },
      deep: true,
      immediate: true,
    },
    "option.visible": {
      //全屏化时执行
      handler: function (newVal, oldVal) {
        if (newVal) {
          if (this.isModal) {
            this.searchObj = this.orginSearchObj;
            this.getData();
          }
        }
      },
      deep: true,
      immediate: true,
    },
  },
  data() {
    return {
      operateWidth: 64, //操作列宽度
      isLoading: false, //数据加载中
      itemList: [],
      searchObj: {
        ITEM: "", //选中的项目
      },
      assetNumber: "",
      detailInfo: [],
    };
  },
  computed: {
    currentSelectDeptInfo() {
      return this.$store.state.currentSelectDeptInfo;
    },
  },
  methods: {
    // 全屏切换
    changeScreen() {
      this.option.visible = !this.isModal;
      if (!this.option.visible) {
        this.getData();
      }
    },
    // 右侧操作列宽度
    getOperateWidth() {
      if (!this.$el.querySelector) {
        return;
      }
      var dom = this.$el.querySelector(".card-head-operate");
      if (!!dom) {
        var width = dom.clientWidth;
        this.operateWidth = width > 0 ? width + 12 : width;
      }
    },
    // 数字转为千位分隔符表示
    toThousandsSeparator: function (value) {
      if (!value) return 0;
      // 获取整数部分
      const intPart = Math.trunc(value);
      // 整数部分处理，增加,
      const intPartFormat = intPart
        .toString()
        .replace(/(\d)(?=(?:\d{3})+$)/g, "$1,");
      // 预定义小数部分
      let floatPart = "";
      // 将数值截取为小数部分和整数部分
      const valueArray = value.toString().split(".");
      if (valueArray.length === 2) {
        // 有小数部分
        floatPart = valueArray[1].toString(); // 取得小数部分
        return intPartFormat + "." + floatPart;
      }
      return intPartFormat + floatPart;
    },

    // 切换项目
    handleChange(value) {
      this.assetNumber = value;
      this.getData();
    },
    getList() {
      let data = {
        Dept: this.currentSelectDeptInfo.CODE,
      };
      let url = Config.dev_url + "/api-apps/charger/assetList";
      this.$axios.post(url, data).then((res) => {
        this.itemList = res.data.data;
        if (this.itemList.length !== 0) {
          this.searchObj.ITEM = this.itemList[0].assetnumber
        }
      });
    },
    //每月报警预警数
    getData() {
      this.isLoading = true;
      let data = {
        assetNumber: this.assetNumber,
      };
      let url = Config.dev_url + "/api-apps/charger/operatingParameter";
      this.$axios.post(url, data).then((res) => {
        console.log(res.data.data);
        this.detailInfo = res.data.data
      });
      this.isLoading = false;
    },
  },
  mounted() {
    this.getOperateWidth();
    this.getList();
    if (!this.option.visible) {
      this.getData();
    }
  },
};
</script>

<style lang="less" scoped>
.cdzBox8 {
  .card-content1 {
    display: flex;
    > div {
      width: 100%;
      &:nth-child(1) {
        width: 364px;
        height: 279px;
        box-sizing: border-box;
        padding: 0 34px 26px;
        display: flex;
        justify-content: center;
        img {
          height: 100%;
          width: auto;
        }
      }
      &:nth-child(2) {
        flex: 1;
        display: flex;
        flex-wrap: wrap;
        div {
          width: 180px;
          height: 128px;
          display: flex;
          flex-direction: column;
          span {
            font-size: 16px;
            font-weight: 400;
            color: #595959;
            &:nth-child(1) {
              margin-bottom: 6px;
            }
            &:nth-child(2) {
              font-size: 12px;
              font-weight: 400;
              color: #9f9f9f;
              line-height: 20px;
              font {
                font-size: 36px;
                font-weight: 500;
                color: #1f1f1f;
                line-height: 48px;
                margin-right: 4px;
              }
              .hot-num {
                color: #51a34b;
              }
            }
          }
        }
      }
    }
  }
  .card-content2 {
    margin-top: 27px;
    display: flex;
    justify-content: space-between;
    > div {
      width: 100%;
      display: flex;
      align-items: center;
      margin-right: 23px;
      .content-li-line-box {
        margin-right: 12px;
        margin-left: 16px;
        flex: 1;
      }
    }
    .content-li-line-box {
      height: 8px;
      display: flex;
      background: #dbdbdb;
      margin: 12px 0 9px;
      span {
        background: #25b131;
        height: inherit;
        width: 0%;
        display: inline-block;
      }
    }
  }
}
</style>
